'use client';

import { useEffect, useState } from 'react';

import { User } from 'next-auth';
import { useTranslations } from 'next-intl';

import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { collectFeedback } from 'utils/collectUserInputs';

import { Button } from '../ui/button';
import { Checkbox } from '../ui/checkbox';
import { Input } from '../ui/input';
import { Textarea } from '../ui/textarea';

interface IAppFeedbackProps {
  text: string;
  landingPage?: boolean;
  user?: User;
  onSubmit?: () => void;
  disabled?: boolean;
}

const AppFeedback = ({
  text,
  landingPage,
  user,
  onSubmit,
  disabled,
}: IAppFeedbackProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [email, setEmail] = useState<string>(user?.email ? user.email : '');
  const [contact, setContact] = useState(user ? false : true);
  const [loading, setLoading] = useState(false);
  const t = useTranslations('Header.feedback');

  useEffect(() => {
    setEmail(user?.email ? user.email : '');
  }, [user]);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleSubmit = async () => {
    if (feedback === '') {
      return;
    }
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setIsOpen(false);
      onSubmit && onSubmit();
    }, 1300);

    await collectFeedback(feedback, email);
    setFeedback('');
  };

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild disabled={disabled}>
        <Button
          variant={landingPage ? 'ghost' : 'secondary'}
          size="sm"
          className="items-center text-sm font-medium flex backdrop-blur-sm bg-opacity-20"
        >
          {text}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="mr-6 mt-3 h-full w-80 rounded-md border border-gray-200 bg-white p-4 text-sm shadow-md focus-within:outline-2"
        side="bottom"
        align="start"
        sideOffset={5}
        onOpenAutoFocus={handleOpen}
      >
        {/* Popover content */}
        {loading ? (
          <div className="flex h-full flex-col items-center justify-center self-center py-10 align-middle">
            <p className="mb-4 align-middle text-gray-500">{t('submitting')}</p>
            <svg
              className="h-5 w-5 animate-spin align-middle text-gray-500"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M12 2a7 7 0 00-7 7v5a7 7 0 0014 0V9a7 7 0 00-7-7m0 2a5 5 0 015 5v5a5 5 0 01-10 0V9a5 5 0 015-5z"
              />
            </svg>
          </div>
        ) : (
          <form
            id="form-feedback"
            onSubmit={handleSubmit}
            className="flex flex-col space-y-3"
          >
            <Textarea
              className="w-full max-h-96"
              rows={4}
              name="feedback"
              placeholder={t('subtitle')}
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
            />
            {!user && (
              <>
                <div className="flex items-center space-x-2">
                  <Checkbox
                    id="contact"
                    checked={contact}
                    onCheckedChange={() => setContact(!contact)}
                  />
                  <label
                    htmlFor="contact"
                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  >
                    {t('contact_me')}
                  </label>
                </div>
                {contact ? (
                  <Input
                    className="mb-2 w-full resize-none"
                    type="email"
                    name="email"
                    id="email-feedback"
                    aria-labelledby="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                ) : null}
              </>
            )}

            <Button type="submit" size="sm" className="rounded-lg">
              {t('submit')}
            </Button>
          </form>
        )}
      </PopoverContent>
    </Popover>
  );
};
export default AppFeedback;
