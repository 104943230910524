export const SUPPORTED_LANGS = ['fr', 'en', 'de'] as const;
export const DEFAULT_LANG = 'fr';

// ISO 639-1 language code - ISO 3166-1 alpha-2 country code
export const languageToCountryMapping = {
  fr: 'fr-FR',
  en: 'en-US',
  de: 'de-DE',
} as const;

export function getCountryName(
  locale: string,
  countryCode: string | null
): string | undefined {
  if (!countryCode) {
    return undefined;
  }
  const regionNames = new Intl.DisplayNames([locale], { type: 'region' });
  return regionNames.of(countryCode);
}
