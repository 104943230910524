import Image from 'next/image';
import { useTranslations } from 'next-intl';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';

import SVGLogo from '../../public/assets/favicons/logo_railee.svg';
import { Badge } from '../ui/badge';

import { AppLogoType } from './AppLogoType';

interface IAppLogoProps {
  className?: string;
  type?: AppLogoType;
}

const AppLogo = ({
  className = 'text-black',
  type = AppLogoType.TEXT,
}: IAppLogoProps) => {
  const t = useTranslations('lobby');

  switch (type) {
    case AppLogoType.LOGO:
      return <SVGLogo className="h-9 w-9" />;
    case AppLogoType.TEXT:
      return (
        <div className="flex items-center justify-start relative">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <Badge
                  variant="secondary"
                  className="absolute -top-0 z-10 -right-5 md:-right-8 text-xxs bg-orange/10 hover:bg-orange/30 md:text-xs px-1.5 py-0 md:px-2.5 md:py-0.5"
                >
                  beta
                </Badge>
              </TooltipTrigger>
              <TooltipContent side="bottom" className="w-full">
                <p className="text-sm w-40">{t('logoTooltip')}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <Image
            src="/assets/favicons/logo_railee.svg"
            alt="Logo Railee"
            className="w-8 h-8 md:w-9 md:h-9 -mt-2 -mb-1.5 -ml-4"
            height={44}
            width={44}
            quality={100}
          />
          <p
            className={`px-2 text-lg sm:text-3xl font-semibold font-prosto ${className}`}
          >
            Railee
          </p>
        </div>
      );
    default:
      break;
  }
};

export default AppLogo;
