'use client';

import { useState } from 'react';

import { CheckCircleIcon } from '@heroicons/react/solid';
import Image from 'next/image';
import { useSearchParams } from 'next/navigation';
import { useLocale, useTranslations } from 'next-intl';
import { createSharedPathnamesNavigation } from 'next-intl/navigation';

import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from '@/components/ui/dropdown-menu';
import { SUPPORTED_LANGS } from 'utils/localisation';

interface Props {
  dark?: boolean;
}

const LanguageSelection = ({ dark = false }: Props) => {
  const locale = useLocale();
  const { useRouter, usePathname } = createSharedPathnamesNavigation({
    locales: SUPPORTED_LANGS,
  });
  const pathname = usePathname();
  const router = useRouter();
  const searchParams = useSearchParams();
  const goToTranslation = (value) => {
    const searchStr = `?${searchParams?.toString()}`;
    const currentPath = `${pathname}${searchStr}`;
    router.replace(currentPath, { locale: value });
  };

  const [language, setLanguage] = useState<string>();
  const t = useTranslations('Header.Languages');

  if (language === undefined) {
    setLanguage(locale);
  }

  return (
    <>
      <MobileSwitcher language={language} t={t} goToTranslation={goToTranslation} />
      <DesktopSwitcher
        language={language}
        t={t}
        goToTranslation={goToTranslation}
        dark={dark}
      />
    </>
  );
};

function DesktopSwitcher({ language, t, dark, goToTranslation }) {
  return (
    <div className="group relative z-10 hidden text-left md:inline-block" tabIndex={-1}>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            className={`${dark ? 'bg-primary ' : 'md:bg-transparent'} inline-flex h-9 items-center justify-center gap-2 overflow-hidden whitespace-nowrap rounded-full text-sm font-medium leading-5 outline-none focus-within:border-transparent focus-within:outline focus-within:outline-2 hover:bg-gray-100 active:bg-gray-50 active:text-gray-800 md:bg-opacity-30 md:py-2 md:backdrop-blur-sm`}
          >
            <Image
              className="h-auto rounded-sm md:h-3"
              alt={`Flag of ${t(language)}`}
              src={`/assets/flags/${language}.svg`}
              width={18}
              height={12}
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            ></Image>

            <span className="hidden md:block">{t(language) ?? language}</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-40">
          <DropdownMenuLabel>{t('title')}</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuRadioGroup value={language} onValueChange={goToTranslation}>
            {SUPPORTED_LANGS.map((language_disp) => (
              <DropdownMenuRadioItem
                className={`relative inline-flex w-full cursor-pointer items-center justify-start gap-2 px-4 py-3 pl-8 text-left text-sm text-gray-700 hover:bg-gray-200 focus-visible:outline-1 focus-visible:outline-gray-500 ${
                  language == language_disp ? ' border-primary' : ''
                } `}
                key={language_disp}
                id={language_disp}
                value={language_disp}
              >
                <Image
                  className="h-3 rounded-sm"
                  alt={t(language_disp)}
                  src={`/assets/flags/${language_disp}.svg`}
                  width={18}
                  height={12}
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                ></Image>
                <p
                  className={`text-sm ${
                    language == language_disp ? 'font-bold' : 'font-medium'
                  } leading-5`}
                >
                  {t(language_disp)}
                </p>
              </DropdownMenuRadioItem>
            ))}
          </DropdownMenuRadioGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}

//mobile menu
function MobileSwitcher({ language, t, goToTranslation }) {
  return (
    <div
      className="group relative z-10 inline-block text-left md:hidden w-max"
      tabIndex={-1}
    >
      <Dialog>
        <DialogTrigger asChild>
          <Button
            variant="ghost"
            className="inline-flex h-9 items-center justify-center gap-2 overflow-hidden whitespace-nowrap rounded-full px-2 text-sm font-medium leading-5 outline-none focus-within:border-transparent focus-within:outline focus-within:outline-2 hover:bg-gray-100 active:bg-gray-50 active:text-gray-800  md:bg-white md:bg-opacity-30 md:py-2 md:pl-3.5 md:pr-3  md:backdrop-blur-sm"
          >
            <Image
              className="h-auto rounded-sm md:h-3"
              alt={`Flag of ${t(language)}`}
              src={`/assets/flags/${language}.svg`}
              width={18}
              height={12}
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            ></Image>

            <span className="hidden md:block">{t(language)}</span>
          </Button>
        </DialogTrigger>
        <DialogContent className="top-0 rounded-2xl sm:max-w-[325px]">
          <DialogHeader>
            <DialogTitle>{t('title')}</DialogTitle>
            <DialogDescription></DialogDescription>
          </DialogHeader>
          {SUPPORTED_LANGS.map((language_disp) => (
            // eslint-disable-next-line localrules/unique-ids
            <div
              className="relative inline-flex w-full cursor-pointer items-center justify-start gap-2 rounded-md px-4 py-3 text-left text-sm text-gray-700 hover:bg-gray-100 focus-visible:bg-primary "
              key={language_disp}
              id={language_disp}
              onClick={() => goToTranslation(language_disp)}
            >
              <Image
                className="h-3 rounded-sm"
                alt={t(language_disp)}
                src={`/assets/flags/${language_disp}.svg`}
                width={18}
                height={12}
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                }}
              ></Image>
              <p
                className={`text-md ${
                  language == language_disp ? 'font-bold' : 'font-medium'
                } leading-5`}
              >
                {t(language_disp)}
              </p>
              <p className="absolute right-3 truncate text-sm font-medium leading-5 text-gray-900 text-primary">
                {language == language_disp ? <CheckCircleIcon className="h-5" /> : ''}
              </p>
            </div>
          ))}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default LanguageSelection;
