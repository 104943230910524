/* eslint-disable no-commented-code/no-commented-code */
import { PrismaClient } from '@prisma/client';

const globalForPrisma = globalThis as unknown as { prisma: PrismaClient };

// eslint-disable-next-line prefer-const
let paramsPrismaClient = {};

// To have log in dev uncomment this
//if (process.env.NODE_ENV !== 'production') {
//  paramsPrismaClient = { log: ['query', 'info', 'warn', 'error'] };
//}

const prisma = globalForPrisma.prisma || new PrismaClient(paramsPrismaClient);

if (process.env.NODE_ENV !== 'production') {
  globalForPrisma.prisma = prisma;
}

export default prisma;
