'use client';

import { Dispatch, SetStateAction, createContext, useContext, useState } from 'react';

import { URL } from 'whatwg-url';

import { searchEndpoint } from 'hooks/querySearch';
import { getBaseUrl } from 'lib/utilsClientSide';

const defaultUrl = new URL(searchEndpoint, getBaseUrl()) as URL;

const RedirectUrlContext = createContext<[URL, Dispatch<SetStateAction<URL>>]>([
  defaultUrl,
  () => {},
]);

type Props = {
  children?: React.ReactNode;
};

export const RedirectUrlProvider = ({ children }: Props) => {
  const [redirectUrl, setRedirectUrl] = useState<URL>(defaultUrl) as [
    URL,
    Dispatch<SetStateAction<URL>>,
  ];

  return (
    <RedirectUrlContext.Provider value={[redirectUrl, setRedirectUrl]}>
      {children}
    </RedirectUrlContext.Provider>
  );
};

export const useRedirectUrl = () => {
  const [redirectUrl, setRedirectUrl] = useContext(RedirectUrlContext);

  const setRedirectUrlToCurrentPage = () => {
    // Defensive programming here we should always have a window object defined here
    const href = window?.location.href ?? defaultUrl.href;
    setRedirectUrl(new URL(href));
  };

  return [redirectUrl.toString(), setRedirectUrl, setRedirectUrlToCurrentPage] as [
    string,
    Dispatch<SetStateAction<string>>,
    () => void,
  ];
};
