'use client';

import { useEffect, useState } from 'react';

import { useRouter } from 'next/navigation';
import { useSession } from 'next-auth/react';

import { Dialog, DialogContent, DialogFooter, DialogTitle } from '@/components/ui/dialog';
import { useRedirectUrl } from 'context/redirectUrlProvider';

import AppleLoginBtn from './AppleLoginBtn';
import EmailLoginBtn from './EmailLoginBtn';
import { GoogleLoginBtn } from './GoogleLoginBtn';
import { LoginModalContent } from './LoginModalContent';
import { useTranslations } from 'next-intl';

export const LoginModal = ({ hideClose = false, onClose = () => {} }) => {
  const router = useRouter();
  const session = useSession()?.data;
  const isAlreadyLoggedIn = session ? true : false;
  const [isOpen, setIsOpen] = useState(false);
  const [redirectUrl] = useRedirectUrl();
  const t = useTranslations('SignUpModal');

  useEffect(() => {
    setIsOpen(true);
  }, []);

  useEffect(() => {
    if (isAlreadyLoggedIn) {
      setIsOpen(false);
      router.push(redirectUrl);
    }
  }, [isAlreadyLoggedIn]);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogTitle className="text-center">Login</DialogTitle>
      <DialogContent
        hideClose={hideClose}
        className="border-primary overflow-hidden place-items-center relative px-0 pt-0"
      >
        <LoginModalContent />
        <DialogFooter className="flex !flex-col items-center !space-x-0 self-center">
          <GoogleLoginBtn />
          <AppleLoginBtn />
          <div className="flex items-center mt-4 mb-1 px-9 w-full">
            <hr className="flex-grow border-t border-gray-300" />
            <span className="text-xs mx-2 text-gray-500">{t('or')}</span>
            <hr className="flex-grow border-t border-gray-300" />
          </div>
          <EmailLoginBtn />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
