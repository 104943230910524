import { MODE_FEEDBACK, MODE_QUOTE, QuoteTripDetails } from 'pages/api/collectUserInputs';

async function collectQuote(email: string, quote: QuoteTripDetails) {
  await fetch('/api/collectUserInputs', {
    method: 'POST',
    body: JSON.stringify({ mode: MODE_QUOTE, email: email, quote: quote }),
  });
}

async function collectFeedback(feedback: string, email: string) {
  await fetch('/api/collectUserInputs', {
    method: 'POST',
    body: JSON.stringify({ mode: MODE_FEEDBACK, email: email, feedback: feedback }),
  });
}

export { collectFeedback, collectQuote };
