'use client';

import { useEffect, useState } from 'react';

import { useTranslations } from 'next-intl';

import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from '@/components/ui/input-otp';

const formId = 'form-gtm-otp';

export function InputOTPForm({
  email,
  callbackUrl,
}: {
  email: string;
  callbackUrl: string;
}) {
  const t = useTranslations('SignUpModal');
  const [token, setToken] = useState<string>('');

  useEffect(() => {
    if (token.length === 6) {
      (document.getElementById(formId) as HTMLFormElement).submit();
    }
  }, [token]);

  function handlePaste(e) {
    e.preventDefault();

    const pastedData = e.clipboardData
      .getData('text/plain')
      .replace(/\D/g, '')
      .slice(0, 6);

    if (pastedData.length > 0) {
      if (token.length + pastedData.length == 6) {
        setToken(token + pastedData);
      } else {
        setToken(pastedData);
      }
    }
  }

  return (
    <div className="space-y-2">
      <InputOTP
        maxLength={6}
        value={token}
        onChange={(value) => setToken(value)}
        onPaste={handlePaste}
      >
        <InputOTPGroup>
          <InputOTPSlot index={0} />
          <InputOTPSlot index={1} />
          <InputOTPSlot index={2} />
        </InputOTPGroup>
        <InputOTPSeparator className="font-bold" />
        <InputOTPGroup>
          <InputOTPSlot index={3} />
          <InputOTPSlot index={4} />
          <InputOTPSlot index={5} />
        </InputOTPGroup>
      </InputOTP>
      <div className="text-center text-sm text-slate-500 font-thin">
        {t('magic_code_enter')}
      </div>
      <form id={formId} action="/api/auth/callback/email" method="get">
        <input id="token" type="hidden" name="token" value={token} />
        <input id="callbackUrl" type="hidden" name="callbackUrl" value={callbackUrl} />
        <input id="email" type="hidden" name="email" value={email} />
      </form>
    </div>
  );
}
