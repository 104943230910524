'use client';

import { signIn } from 'next-auth/react';
import { useTranslations } from 'next-intl';

import { Button } from '@/components/ui/button';
import { Icons } from '@/components/ui/icons';
import { useRedirectUrl } from 'context/redirectUrlProvider';

const WAITING_FOR_APPLE_ID = true;

const AppleLoginBtn = () => {
  const t = useTranslations('SignUpModal');
  const [redirectUrl, ,] = useRedirectUrl();

  const handleLogin = async () => {
    await signIn('apple', { callbackUrl: redirectUrl.toString() });
  };

  if (WAITING_FOR_APPLE_ID) {
    return null;
  }

  return (
    <Button
      variant="outline"
      id="button-gtm-apple-login"
      size="lg"
      className="font-semibold mt-3 w-fit"
      onClick={handleLogin}
    >
      <span id="span-gtm-apple-login" className="flex flex-row gap-2.5 items-center">
        <Icons.apple className="h-5 inline-block" />
        {t('oauth_apple')}
      </span>
    </Button>
  );
};

export default AppleLoginBtn;
