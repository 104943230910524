import Image from 'next/image';
import { useTranslations } from 'next-intl';

export const LoginModalContent = () => {
  const t = useTranslations('SignUpModal');

  return (
    <div className="place-items-center w-full">
      <Image
        className="mx-auto h-36 bg-cover w-full mb-8 opacity-60 object-cover"
        alt="price_map"
        width="800"
        src="/assets/login_bg_image.jpg"
        height="600"
      />
      <div className="top-24 right-0 left-0 mx-auto absolute flex flex-row justify-center -space-x-5">
        <Image
          className="rounded-full shadow-sm bg-cover border-2 border-white h-16 w-16 z-40"
          alt="Community Image"
          width="300"
          src="/assets/users/martin.jpg"
          height="300"
        />
        <Image
          className="rounded-full shadow-sm bg-cover border-2 border-white h-16 w-16 z-30"
          alt="Community Image"
          width="300"
          src="/assets/users/julie.jpg"
          height="300"
        />
        <Image
          className="rounded-full shadow-sm bg-cover border-2 border-white h-16 w-16 z-20"
          alt="Community Image"
          width="300"
          src="/assets/users/sebastien.jpg"
          height="300"
        />
        <Image
          className="rounded-full shadow-sm bg-cover border-2 border-white h-16 w-16"
          alt="Community Image"
          width="300"
          src="/assets/users/quentin_francois.jpg"
          height="300"
        />
      </div>
      <p className="text-2xl font-bold text-center"> {t('title')}</p>
      <p className="text-sm text-slate-500 -mt-1 text-center">{t('subtitle')}</p>
    </div>
  );
};
