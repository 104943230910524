'use client';

import { useEffect, useRef, useState } from 'react';

import { signIn } from 'next-auth/react';
import { useTranslations } from 'next-intl';

import { Button } from '@/components/ui/button';
import { Icons } from '@/components/ui/icons';
import { useRedirectUrl } from 'context/redirectUrlProvider';

import { InputOTPForm } from './OTPForm';

enum FormState {
  Start = 'Start',
  EmailInput = 'EmailInput',
  FormSubmitted = 'FormSubmitted',
}

const EmailLoginBtn = () => {
  const t = useTranslations('SignUpModal');
  const [redirectUrl, ,] = useRedirectUrl();
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');

  const [formState, setFormState] = useState<FormState>(FormState.Start);

  const handleLogin = async (e) => {
    e.preventDefault();

    setFormState(FormState.FormSubmitted);

    const signInResponse = await signIn('email', {
      email,
      callbackUrl: redirectUrl.toString(),
      redirect: false,
    });

    if (signInResponse?.error) {
      console.error('Error signing in:', signInResponse.error);
      setError(signInResponse.error);
    }
  };

  const emailInputRef = useRef<HTMLInputElement>(null);
  const isAnEmailRegex = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
  );
  useEffect(() => {
    if (formState === FormState.EmailInput && emailInputRef.current) {
      emailInputRef.current.focus();
    }
  }, [formState]);

  return (
    <>
      <form id="form-email-login" onSubmit={handleLogin} className="ml-0">
        <div className="items-center flex flex-col w-full">
          {formState === FormState.EmailInput && (
            <input
              ref={emailInputRef}
              type="email"
              id="input-email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mt-3 p-2 border border-gray-300 rounded-md focus:outline-orange"
              placeholder="Email"
            />
          )}
          {formState !== FormState.FormSubmitted && (
            <Button
              id="email-login-button"
              size="lg"
              className="font-semibold mt-3 w-fit"
              //if not valid email, disable button
              disabled={formState === FormState.EmailInput && !isAnEmailRegex.test(email)}
              onClick={
                formState === FormState.Start
                  ? () => setFormState(FormState.EmailInput)
                  : (e) => handleLogin(e)
              }
              variant={formState === FormState.Start ? 'default' : 'confirmative'}
              type="button"
            >
              {(() => {
                switch (formState) {
                  case FormState.Start:
                    return (
                      <span className="flex flex-row gap-2.5 items-center">
                        <Icons.email className="h-5 inline-block" />
                        {t('magic_code')}
                      </span>
                    );
                  case FormState.EmailInput:
                    return (
                      <span className="flex flex-row gap-2.5 items-center">
                        {t('magic_code_send')}
                      </span>
                    );
                  default:
                    return null;
                }
              })()}
            </Button>
          )}
        </div>
      </form>
      {formState === FormState.FormSubmitted && (
        <ConfirmationMessage email={email} redirectUrl={redirectUrl} error={error} />
      )}
      {formState === FormState.FormSubmitted && !error && (
        <InputOTPForm email={email} callbackUrl={redirectUrl.toString()} />
      )}
    </>
  );
};

export default EmailLoginBtn;

const ConfirmationMessage = ({ error, email, redirectUrl }) => {
  const t = useTranslations('SignUpModal');

  if (error) {
    return (
      <span className="flex flex-row gap-2.5 items-center">
        <Icons.email className="h-5 inline-block" />
        {`Error while sign-in up: ${error}. Please try again.`}
      </span>
    );
  }
  return (
    <span className="flex flex-row gap-2.5 items-center text-orange my-2">
      <Icons.email className="h-5 inline-block" />
      {t('magic_code_sent')}
    </span>
  );
};
