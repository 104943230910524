import { useEffect, useState } from 'react';

function useIphoneDetection() {
  const [isIphone, setIsIphone] = useState<boolean>(false);

  useEffect(() => {
    if (navigator.userAgent.match(/iPhone/i)) {
      setIsIphone(true);
    }
  }, []);

  return isIphone;
}

export default useIphoneDetection;
